.CommerceOptionsHeader{
    top:-1rem;
}
.logoBenefyHeader{
    left:-5rem;
}
.NavBarHeader{
    background: url("../Img/img_encabezado.png") no-repeat;
    background-size: cover;
   
}

.linkTextHeader{
    color:white !important;
    font-size: 18px;
}
.DropDownHeaderCountry{
    color: white;
    border: none;
    background: none;
    -webkit-appearance:none
}
.is-active{
    font-weight: bold;
}
.HeadermenuItems{
    margin-right: 2rem !important;
}
@media (max-width: 600px) {
    .navBarToggleHeader {
        margin-left:6rem;
    }
    .HideMobileHeader{
        display: none;
    }
    .CommerceOptionsHeader{
        top:0;
       
    }
    .logoBenefyHeader{
        left:0rem;
    }
}

@media (min-width: 1281px) {
  
    /* CSS */
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    /* CSS */
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    /* CSS */
    
  }
  .badge {
    padding-left: 9px;
    padding-right: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
  }
  
  .label-warning[href],
  .badge-warning[href] {
    background-color: #c67605;
  }
  #lblCartCount {
      font-size: 12px;
      background: #ff0000;
      color: #fff;
      padding: 0 5px;
      vertical-align: top;
      margin-left: -10px; 
      height: 12px;
  }