.CartCheckoutHeaderText{
    color:white !important;
    font-size:1rem !important;
    font-weight: 100;
}
.CartCheckoutTitleElement{
    font-size: 1.3rem;
}
.CartCheckoutSubTitleElement{
    font-size: 1.2rem;
    color: var(--graySecundary);
}
.CartCheckoutImageCoupon{
    width: 10rem;
}
.CartCheckoutPrice{
    color: #05a2d2;
    font-size: 1.5rem;
}
@media (min-width: 1281px) {
  
    /* CSS */
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    /* CSS */
    .CartCheckoutHeaderText{
        color:white !important;
        font-size:0.8rem;
    }
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    /* CSS */
    .CartCheckoutHeaderText{
        color:white !important;
        font-size:0.5rem;
    }
    
  }