.JoinToBenefyPagePrincipalBack{
    background-image: url('../Img/img_encabezado.png'); background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}


.JoinToBenefyPageLineText {
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .JoinToBenefyPageLineText::before,
  .JoinToBenefyPageLineText::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #fff;
  }
  
  .JoinToBenefyPageLineText:not(:empty)::before {
    margin-right: 1em;
  }
  
  .JoinToBenefyPageLineText:not(:empty)::after {
    margin-left: 1em;
  }

 .JoinToBenefyPageLogoSize{
     width: 35%;
 } 
@media (min-width: 1281px) {
  
    /* CSS */
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    .JoinToBenefyPageLogoSize{
        width: 50%;
    } 
    /* CSS */
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    .JoinToBenefyPageLogoSize{
        width: 70%;
    } 
    /* CSS */
    
  }

  