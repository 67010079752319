

/* form starting stylings ------------------------------- */
.group 			  { 
    position:relative; 
    margin-bottom:45px; 
  }
  .BenefyTextArea {
    font-size:18px;
    padding:10px 10px 10px 5px;
    display:block;
    width:100%;
    border:none;
    border-bottom:1px solid var(--graySecundary) !important;
  }
  .BenefyTextArea:focus { outline:none; }
  
  /* LABEL ======================================= */
  .labelBenefyTextArea  {
    color:#999; 
    font-size:18px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:5px;
    top:10px;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  
  /* active state */
  .BenefyTextArea:focus ~ .labelBenefyTextArea, .BenefyTextArea:valid ~ .labelBenefyTextArea  			{
    top:-20px;
    font-size:14px;
    color:var(--principalOrange);;
  }
  
  /* BOTTOM BARS ================================= */
  .bar 	{ position:relative; display:block; width:300px; }
  .bar:before, .bar:after 	{
    content:'';
    height:2px; 
    width:0;
    bottom:1px; 
    position:absolute;
    background:var(--principalOrange);; 
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  .bar:before {
    left:50%;
  }
  .bar:after {
    right:50%; 
  }
  
  /* active state */
  .BenefyTextArea:focus ~ .bar:before, .BenefyTextArea:focus ~ .bar:after {
    width:50%;
  }
  
  /* HIGHLIGHTER ================================== */
  .highlight {
    position:absolute;
    height:60%; 
    width:100px; 
    top:25%; 
    left:0;
    pointer-events:none;
    opacity:0.5;
  }
  
  /* active state */
  .BenefyTextArea:focus ~ .highlight {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
  }
  input[type="date"]::before {
    content: attr(placeholder);
    position: absolute;
    color: #999999;
  }
  
  input[type="date"] {
    color: #ffffff;
  }
  
  input[type="date"]:focus,
  input[type="date"]:valid {
    color: #666666;
  }
  
  input[type="date"]:focus::before,
  input[type="date"]:valid::before {
    content: "";
  }