
@keyframes ldio-ot29bi80foj-1 {
    0% { top: 36px; height: 128px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
  }
  @keyframes ldio-ot29bi80foj-2 {
    0% { top: 41.99999999999999px; height: 116.00000000000001px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
  }
  @keyframes ldio-ot29bi80foj-3 {
    0% { top: 48px; height: 104px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
  }
  .ldio-ot29bi80foj div { position: absolute; width: 30px }.ldio-ot29bi80foj div:nth-child(1) {
    left: 35px;
    background: #fd8a0d;
    animation: ldio-ot29bi80foj-1 1s cubic-bezier(0,0.5,0.5,1) infinite;
    animation-delay: -0.2s
  }
  .ldio-ot29bi80foj div:nth-child(2) {
    left: 85px;
    background: #838383;
    animation: ldio-ot29bi80foj-2 1s cubic-bezier(0,0.5,0.5,1) infinite;
    animation-delay: -0.1s
  }
  .ldio-ot29bi80foj div:nth-child(3) {
    left: 135px;
    background: #f97716;
    animation: ldio-ot29bi80foj-3 1s cubic-bezier(0,0.5,0.5,1) infinite;
    animation-delay: undefineds
  }
  
  .loadingio-spinner-pulse-fb0b2e07ls {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background:none;
  }
  .ldio-ot29bi80foj {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-ot29bi80foj div { box-sizing: content-box; }
  
  
  /*full loader*/

  