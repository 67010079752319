.ErrorPageTitle{
    color:var(--principalOrange);
    font-weight: bold;
    font-size: 30px;
    position: relative;
    text-align: center
  
  }
  .ErroPageSubtitle{
    color:var(--grayPrimary);
    font-size: 25px;
    position: relative;
    text-align: center
  }