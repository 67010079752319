@font-face {
  font-family: 'Circular Std Book';
  font-style: normal;
  font-weight: 400;
  src:  url('./Style/Font/CircularStdBook.ttf')  format('truetype')
}
:root {
  --principalOrange:#f97716;
  --secondOrange:#FD8A0D;
  --grayPrimary:#777777;
  --whiteBenefy:White;
  --graySecundary:#B9B9C7;
}
#root{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: 'Circular Std Book' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
h1{
  color:var(--principalOrange);
  font-weight: bold !important;
  font-size: 50px;
  position: relative;
  text-align: center

}
h2{
  color:var(--principalOrange);
  font-weight: bold !important;
  font-size: 37px ;
}
p{
  color: var(--grayPrimary);
  font-size: 1.2rem;
}
.graySubtitle{
  color: var(--grayPrimary);
}
.UnderlinePageTitle::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -8px;
  width: 40px;
  border-bottom: 4px solid var(--principalOrange);;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.WhiteTitlePage{
  color:var(--whiteBenefy);
  font-size: 3.3rem;
  font-weight: bold;
}

.WhiteSubtitlePage{
  color:var(--whiteBenefy);
  font-size: 1.2rem;
  font-weight: 100;
}
.grayContainer{
  background-color: #f1f1f1;
}
.orangeButton{
  background:var(--principalOrange) !important;
}
.orangeSecundaryButton{
  background:var(--secondOrange) !important;
}
.BenefyWavesBackgroud{
  background: url("/src//Style/Img/img_encabezado.png") no-repeat;
  background-size: cover;
}
.whiteButton{
  background:var(--whiteBenefy) !important;
  color: var(--principalOrange)  !important;
  font-size: 1.3rem  !important;
}
hr{
  color: var(--secondOrange);
  background-color: var(--secondOrange);
  height: 0.7px !important;
}

@media (max-width: 768px) {
  .navbar-collapse {
      position: fixed;
      top: 0;
      left: 0;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      width: 75%;
      height: 100%;
      background-color: var(--secondOrange);
      z-index: 9999;
  }

  .navbar-collapse.collapsing {
      left: -75%;
      transition: height 0s ease;
  }

  .navbar-collapse.show {
      left: 0;
      transition: left 500ms ease-in-out;
  }

  
}
.form-control {
border-top: none  !important;
border-left: none  !important;
border-right: none  !important;
}

.form-control:focus {
  box-shadow:none !important;
  border-color:transparent  !important;
}
.inputwarningP{
  color:red;
  font-size: 0.9rem;
  font-weight: 100;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: var(--whiteBenefy) !important;
  background-color: var(--graySecundary) !important;
}
.BlueAItems {
  color: #05a2d2 !important;
  text-decoration: none;
  background-color: transparent;
}
.grayInput{
  text-align: left;
  width: 100%;
  height: 3rem;
  background-color: #f0f0f0;
  color: black;
  display: inline-block;
  border-radius: 10px;
  border: none;
}