/*.iconEmail{
    background: url('../img/Email.svg');
    height: 20px;
    width: 20px;
    display: block;
}
.iconPhone {
    background: url('../img/Teléfono.svg');
    height: 20px;
    width: 20px;
    display: block;
}*/
.FooterMenuOptions{
    width: auto;
}
.FooterMarginItemsMenu{
    margin-left: 1rem;
}
@media (max-width: 400px){
    .FooterMenuOptions{
        width: 100%;
        margin-top: 10px;
    }
}

@media (min-width: 1281px) {
  
    /* CSS */
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    /* CSS */
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    /* CSS */
    
  }
